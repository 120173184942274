import {
  TICKET_TYPE_ADULT,
  TYPE_PROMO_TICKET_FREE,
  TYPE_PROMO_DISCOUNT,
  TICKET_TYPE_SENIOR,
} from "./envs.js";

export const applyDiscount = async (
  details,
  promoCode,
  promoList,
  trip,
  alreadyAppliedPromos
) => {
  let roundTripOnly = true;
  let appliedPromo = false;
  let isError = false;
  let errorMsg = "";
  let promotion = null;
  let updatedDetails = details;
  let promoCodeUpperCase = JSON.stringify(promoCode)
    .toUpperCase()
    .replace(/ /g, "")
    .replace(/"/g, "");

  if (alreadyAppliedPromos) {
    isError = true;
    errorMsg = "Promoción ya aplicada";
    return { updatedDetails, appliedPromo, isError, errorMsg };
  }

  let promoNames = promoList.map((promo) => promo.promo.name);

  if (!promoNames.includes(promoCodeUpperCase)) {
    isError = true;
    errorMsg = "Promoción no valida";
    return { updatedDetails, appliedPromo, isError, errorMsg };
  }

  promotion = promoList.find(
    (promo) => promo.promo.name === promoCodeUpperCase
  );

  //check if promotion date is valid for sale
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  let promotionSaleStart = new Date(
    JSON.stringify(promotion.startDate.split("T")[0])
  );

  const promotionSaleEnd = new Date(
    JSON.stringify(promotion.endDate.split("T")[0])
  );

  if (today < promotionSaleStart || today > promotionSaleEnd) {
    isError = true;
    errorMsg = "Promoción no valida en esta fecha";
    return { updatedDetails, appliedPromo, isError, errorMsg };
  }

  let dateObject;
  let travelStartDate;

  if (!trip.ret && roundTripOnly) {
    isError = true;
    errorMsg = "Promoción Solo Válida para Round Trips";
    return { updatedDetails, appliedPromo, isError, errorMsg };
  }

  //Check if promotion is valid for the date of the trip
  if (trip.ret) {
    dateObject = new Date(trip.depart.departure_date);
  } else {
    dateObject = new Date(trip.departure_date);
  }

  travelStartDate = new Date(
    JSON.stringify(promotion.travelStartDate.split("T")[0])
  );

  const travelEndDate = new Date(
    JSON.stringify(promotion.travelEndDate.split("T")[0])
  );

  if (dateObject < travelStartDate || dateObject > travelEndDate) {
    isError = true;
    errorMsg = "Promoción no valida en esta fecha";
    return { updatedDetails, appliedPromo, isError, errorMsg };
  }

  // Fechas para vender
  const startDate = new Date(JSON.stringify(promotion.startDate.split("T")[0]));
  const endDate = new Date(JSON.stringify(promotion.endDate.split("T")[0]));
  if (today < startDate || today > endDate) {
    isError = true;
    errorMsg = "Promoción no valida en esta fecha";
    return { updatedDetails, appliedPromo, isError, errorMsg };
  }

  const ammountRequired = Number(promotion.promo.condition.split(" ")[0]);

  if (
    promotion.promo.typePromo === TYPE_PROMO_TICKET_FREE ||
    promotion.promo.typePromo === TYPE_PROMO_DISCOUNT
  ) {
    const travellersInfo = details;

    // if ticket free then we should filter only the tickets that match the ticket type
    let ticketsForPromo = [];
    if (promotion.promo.typePromo === TYPE_PROMO_TICKET_FREE) {
      ticketsForPromo = travellersInfo.filter(
        (traveller) =>
          traveller.departure.ticketType === promotion.promo.ticketType
      );
      // if ticket discount we should use all the tickets types
    } else {
      ticketsForPromo = travellersInfo;
    }

    if (!ticketsForPromo?.length) {
      isError = true;
      errorMsg = "No cumple los requisitos para aplicar esta promoción";
      return { isError, errorMsg };
    }

    const adults = details.filter(
      (traveller) =>
        traveller.departure.ticketType === TICKET_TYPE_ADULT ||
        traveller.departure.ticketType === TICKET_TYPE_SENIOR
    );

    const freeTickets = Math.floor(
      promotion.promo.typePromo === TYPE_PROMO_DISCOUNT
        ? ticketsForPromo.length
        : adults.length / ammountRequired
    );

    if (!freeTickets) {
      isError = true;
      errorMsg = "No cumple los requisitos para aplicar esta promoción";
      return { isError, errorMsg };
    }

    // if promotion is recurrent then we should apply the promotion to all the tickets else only to the first one
    let finalAmmountOfPromoTickets = promotion.isRecurrent ? freeTickets : 1;

    //convert number to %
    let discountPorcentage = promotion.promo.value / 100;

    let newDetails = [];
    for (let i = 0; i < finalAmmountOfPromoTickets; i++) {
      newDetails = details.map((traveller) => {
        if (
          traveller.identification === ticketsForPromo[i]?.identification &&
          traveller.firstName === ticketsForPromo[i]?.firstName
        ) {
          // Apply discount to price return 1 if price totaal is 0 because of TAXES ticket could not be 0
          if (traveller.departure) {
            traveller.departure.price =
              traveller.departure.price * discountPorcentage -
                traveller.departure.price
                ? Math.ceil(
                  traveller.departure.price -
                  traveller.departure.price * discountPorcentage
                )
                : 1;
            traveller.departure.isPromo = true;
            traveller.departure.promoId = promotion.promoId;
          }
          if (traveller.returning) {
            traveller.returning.price =
              traveller.returning.price * discountPorcentage -
                traveller.returning.price
                ? Math.ceil(
                  traveller.returning.price -
                  traveller.returning.price * discountPorcentage
                )
                : 1;
            traveller.returning.isPromo = true;
            traveller.returning.promoId = promotion.promoId;
          }
        }
        return traveller;
      });
    }

    let updatedDetails = newDetails.length ? newDetails : details;
    return { updatedDetails, appliedPromo: true, isError: false, errorMsg: "" };
  }
  return { updatedDetails, appliedPromo };
};
